import styled from "styled-components";
// import carcharging from "../Images/carcharging.jpg";

export const Span = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 100%; /* Adjust width to occupy full width on mobile */
  padding: 10px;

  @media (min-width: 768px) {
    /* Media query for tablets and larger screens */
    width: 30%; /* Adjust width for larger screens */
  }
`;

export const P = styled.p`
    font-size: 30px;
    font-weight: bold;
    color: white;
`

export const Button = styled.button`
color: #fff;
padding: 15px 25px;
background-color: #38D2D2;
background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
border-radius: 14px;
font-weight: bold;
font-size: 25px;

border: 0;

user-select: none;
-webkit-user-select: none;
touch-action: manipulation;

cursor: pointer;
`
