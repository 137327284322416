import React from "react";
import { Link } from "react-router-dom";
import { ListItem, List, HeaderWrapper, Logo, ContactLink, StyledLink, Button } from "./styled";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import carlogo from '../../carlogo.png';

const Header = () => {
  const instagramUrl = "https://www.instagram.com/km_electric_store/?next=%2F";
  const facebookUrl = "https://www.facebook.com/profile.php?id=61559052394669";

  return (
    <HeaderWrapper>
      <Logo>  KM Electric
      <img src={carlogo} width={100} height={50} color="white"/>
      </Logo>
      <List>
        <Button><ListItem><StyledLink to="/home">דף הבית</StyledLink></ListItem></Button>
        <Button><ListItem><StyledLink to="/chargers">עמדות טעינה</StyledLink></ListItem></Button>
        <Button><ListItem><StyledLink to="/accessories">אביזרי חשמל</StyledLink></ListItem></Button>
        <Button><ListItem><StyledLink to="/our-work">העבודה שלנו</StyledLink></ListItem></Button>
        {/* <ListItem><ContactLink href="/contact">צור קשר</ContactLink></ListItem> */}

        <ListItem style={{ marginRight: "135px" }}>
          <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
            <InstagramIcon style={{ color: 'white'}}/>
          </a>
        </ListItem>
        <ListItem>
          <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
            <FacebookOutlinedIcon style={{ color: 'white'}} />
          </a>
        </ListItem>
      </List>
    </HeaderWrapper>
  );
};

export default Header;


