import styled from "styled-components";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

export const HeaderWrapper = styled.div`
  background-color: #333;
  color: #fff;
  padding: 20px;
  direction: rtl;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: inherit;
`;

export const ListItem = styled.li`
  display: inline;
  margin-left: 20px;
  font-size: 18px;

  &:first-child {
    margin-right: 0;
  }
`;

export const Logo = styled.div`
  //color: #b4946c;
  color: white;
  align-self: flex-start;
  font-size: 30px;
  font-weight: bold;
`;

export const ContactLink = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`;

export const StyledLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &.active {
    color: red; /* Set active link color to red */
  }
`;

export const Button = styled.button`
  color: white;
  background: unset;
  border: unset;
`;
