import React from "react";
import { Link } from "react-router-dom";
import Product from "./Product";
import charger1 from "./ProductsImages/charger1.png";
import smartCharger from "./ProductsImages/smartCharger.png";
import charger from "./ProductsImages/charger.png";

const Chargers = () => {
  const products = [
    {
      id: 1,
      name: "עמדת טעינה חד/תלת פאזי מבית אריסקו",
      price: 10,
      image: charger1,
      inStock: false,
    },
    {
      id: 2,
      name: "עמדת טעינה חכמה חד/תלת פאזי מבית אריסקו",
      price: 20,
      image: smartCharger,
      inStock: true,
    },
    {
      id: 3,
      name: "מטען נייד לרכב חשמלי",
      price: 20,
      image: charger,
      inStock: true,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        marginTop: "56px",
      }}
    >
      {products.map((product) => (
        <>
          {/* <Link
            key={product.id}
            to={{
              pathname: `/product/${product.id}`,
              state: "888888888888", // Pass the product data as state
            }}
            style={{ textDecoration: "none" }} // Add this style to remove default underline
          > */}
            <Product
              key={product.id}
              name={product.name}
              price={product.price}
              image={product.image}
              inStock={product.inStock}
            />
          {/* </Link> */}
        </>
      ))}
    </div>
  );
};

export default Chargers;
