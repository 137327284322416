import React from "react";

import {BackgroundContainer, TextArea, Input, Form, FormContainer, Div} from './styled'

const Contact = () => {
    return (
        <Div>
        <BackgroundContainer />
        <FormContainer>
          <Form>
            <Input type="text" placeholder="Name" />
            <Input type="email" placeholder="Email" />
            <Input type="tel" placeholder="Mobile" />
            <TextArea placeholder="Message"></TextArea>
            <button type="submit">Submit</button>
          </Form>
        </FormContainer>
      </Div>
    );
  };
  
  export default Contact;