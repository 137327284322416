import React from "react";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Contact from "./Components/Contact/Contact";
import Chargers from "./Components/Chargers/Chargers";
import Accessories from "./Components/Accessories/Accessories";
import OurWork from "./Components/OurWork/OurWork";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProductDetails from "./Components/Chargers/ProductDetails";

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/chargers" element={<Chargers />} />
          <Route path="/product/:productId" element={<ProductDetails />} />
          <Route path="/accessories" element={<Accessories />} />
          <Route path="/our-work" element={<OurWork />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
