import React from "react";
import Button from '@mui/material/Button';

const Product = ({ name, price, image, inStock }) => {
  const handleOpenWhatsAppChat = () => {
    const phoneNumber = "+972508439239";

    const message = `שלום, רציתי לקבל פרטים על: ${name}\n`;

    const encodedPhoneNumber = encodeURIComponent(phoneNumber);
    const encodedMessage = encodeURIComponent(message);

    const whatsappURL = `https://wa.me/${encodedPhoneNumber}?text=${encodedMessage}`;

    window.open(whatsappURL, '_blank');
  };

  return (
    <div style={{ width: "200px", margin: "10px 150px", textAlign: "center", background: 'white' }}>
      <img
        src={image}
        alt={name}
        style={{ width: "100%", height: "auto", maxHeight: "200px" }}
      />
      <h3>{name}</h3>

      {/* {inStock ? (
        <p style={{ fontWeight: 'bold' }}>{price}</p>
      ) : (
        <p style={{ color: "red", fontWeight: 'bold' }}>חסר במלאי</p>
      )} */}

      <Button
        variant="contained"
        color="success"
        onClick={handleOpenWhatsAppChat}
      >
        פרטים נוספים
      </Button>
    </div>
  );
};

export default Product;