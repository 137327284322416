import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const ProductDetails = () => {
  const location = useLocation();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const productData = location.state ? location.state.product : null;
        if (productData) {
          setProduct(productData);
        } else {
          setError("Product not found");
        }
        setLoading(false);
      } catch (error) {
        setError("Error fetching product data");
        setLoading(false);
      }
    };

    fetchProductData();
  }, [location.state]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!product) {
    return <div>Error: Product not found</div>;
  }

  return (
    <div style={{ background: "red" }}>
      <h1>Product Details</h1>
      <p>Product Name: {product.name}</p>
      <p>Product Price: {product.price}</p>
    </div>
  );
};

export default ProductDetails;
