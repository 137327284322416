import React from "react";
import { P, Button, Span } from "./styled";
import maintenance from "../Images/maintenance.png";

const Accessories = () => {
  const handleOpenWhatsAppChat = () => {
    const phoneNumber = "+972508439239";

    const message = "שלום! אני מעוניין באביזרי חשמל";

    const encodedPhoneNumber = encodeURIComponent(phoneNumber);
    const encodedMessage = encodeURIComponent(message);

    const whatsappURL = `https://wa.me/${encodedPhoneNumber}?text=${encodedMessage}`;

    window.open(whatsappURL, "_blank");
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        marginTop: "20px",
      }}
    >
      <img src={maintenance} style={{ width: "100%", maxWidth: "1000px" }} />
      <Span>
        <P>הדף בבנייה</P>

        <Button variant="contained" onClick={handleOpenWhatsAppChat}>
          לחץ ליצירת קשר
        </Button>
      </Span>
    </div>
  );
};

export default Accessories;
