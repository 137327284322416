import React from "react";
import carcharging from "../Images/carcharging.jpg";
import { P, Button, Span } from "./styled";

const Home = () => {
  const handleOpenWhatsAppChat = () => {
    const phoneNumber = "+972508439239";

    const message = "שלום! אני מעוניין בהרכבת עמדת טעינה לרכב חשמלי";

    const encodedPhoneNumber = encodeURIComponent(phoneNumber);
    const encodedMessage = encodeURIComponent(message);

    const whatsappURL = `https://wa.me/${encodedPhoneNumber}?text=${encodedMessage}`;

    window.open(whatsappURL, "_blank");
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        marginTop: "20px",
      }}
    >
      <img src={carcharging} style={{ width: "100%", maxWidth: "1000px" }} />
      <Span>
        <P>ברוכים הבאים ל</P>
        <P>KM ELECTRIC</P>

        <Button variant="contained" onClick={handleOpenWhatsAppChat}>
          בוא נהיה בקשר
        </Button>
      </Span>
    </div>
  );
};

export default Home;
